<script>
//import Multiselect from "vue-multiselect";
import { Upload, Dialog} from 'element-ui'
import {required,} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import "vue-multiselect/dist/vue-multiselect.min.css";
import SeoGeneralService from "@/services/SeoGeneralService";
const itemService = new SeoGeneralService();
import 'element-ui/lib/theme-chalk/index.css'


import InfoModel from "@/classes/seo_general";
const infoModel = new InfoModel();

import HelperJS from "@/functions/general";
const helper = new HelperJS();
import ImageModel from "@/classes/arreglo_image";
const imageModel = new ImageModel();


/**
 * Add-product component
 */
const nameSeccion = 'Seo General';
//const namePlural = nameSeccion+'s';
const routeParentName = 'seo-general';
const nameRoute = 'SeoGeneral';

export default {
  page: {
    title: nameSeccion,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      title: nameSeccion,
      actionCreate: true,
      dialogImageUrl: '',
      listImgs:[],
      imgsBash:[],
      dialogVisible: null,
      info_page:{
        routeParentName: null,
        routeBack: null,
        nameSeccion: null,
        routes:
        [
          {value:'general', text:'General'}, 
          {value:'home', text:'Inicio'}, 
          {value:'categoria', text:'Categoria'},
          {value:'arreglo-details', text:'Vista arreglo'}, 
          {value:'tipo', text:'Tipos'}, 
          {value:'search', text:'Busqueda'}, 
          {value:'checkout', text:'Carrito'}, 
          {value:'contact', text:'Contacto'},
          {value:'event', text:'Eventos'}, 
          {value:'form-payment', text:'Formulario de pago'}, 
          {value:'thanks', text:'Gracias'}
      ],   
      },
      categorias: [],
      tipos: [],
      objectData:{},
      items: [
        {
          text: null,
        },
        {
          text: null,
          active: true,
        },
      ],
      submitted: false,

      
      dropzoneOptions: {
        url: null,
        thumbnailWidth: 200,
        maxFilesize: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
      },

      
    };
  },
  validations: {
      objectData: {
        title: {
          required,
        }
      },
    },
  middleware: "authentication",
  
  created(){

    this.initPage();
  },
  
  methods: {
    async initPage(){
        
        this.info_page.routeParentName = routeParentName;
        this.info_page.nameSeccion = nameSeccion;
        this.info_page.routeBack = '/'+routeParentName;
        let text_page = 'Agregar '
        
        this.objectData = JSON.parse(JSON.stringify(infoModel.fillable));
        if(this.$route.name===nameRoute+'Edit'){          
          this.actionCreate = false;
          text_page = 'Editar';

          itemService.getById(this.$route.params.id).then(async response=>{  
            let data = response.data.object_data;
            this.objectData =   JSON.parse(JSON.stringify(new InfoModel(data)));

            let  imageData = JSON.parse(JSON.stringify(imageModel.fillable))
           
            imageData.url = data.path+'?'+data.updated_at;
            imageData.onServer = true;
            let images = [imageData];
            this.listImgs = images;


          })
        }
        this.items[0].text= nameSeccion
        this.items[1].text= text_page+' '+nameSeccion;
        this.info_page.routes = this.info_page.routes.sort((a, b) => (a.text > b.text) ? 1 : -1);
      },
      async updateForm(){
      
          let formData = JSON.parse(JSON.stringify(this.objectData));
          if(this.actionCreate){
            delete formData.id;
            formData.file =  this.listImgs[0].url;
            await itemService.create(formData)
          }else{
            if(!this.listImgs[0].onServer){
              formData.file =  this.listImgs[0].url;
            }
            await itemService.update(formData)
          }
          this.successForm(this.info_page.routeBack);
      
      },

      successForm(route){
        Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
        this.$router.push({
              path: route,
            });
        });
    },
    cancelForm(){
      this.$router.push({
        path:'/'+routeParentName,
      });
    },
    sendingEvent (file, xhr, formData) {
        formData.append('title', this.objectData.title);
      },
      
      validateForm(){

        this.submitted = true;
        console.log('validaciones');
        console.log(this.$v.$error===false,  this.$v.$invalid===false);
        if(this.$v.$error===false &&  this.$v.$invalid===false){
          this.updateForm();
        }
        
      this.$v.$touch();
      },
      slugify(stringValue){

        return helper.convertToSlug(stringValue);
      },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },


addAttachment(file){
  
  
    const image = file.file;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    
    reader.onload = e =>{


      let  data = JSON.parse(JSON.stringify(imageModel.fillable))
     
        data.url= e.target.result;
        data.onServer = false;
      
        this.listImgs.push(data);
    };
},
handleExceed(file, fileList) {
   //console.log('handleExceed', fileList);
    if (fileList.length === 1) {

      Swal.fire({
            type: 'warning',
            icon: "warning",
            text: 'Se ha excedido el número imagenes',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
        })

        return false;
    }
  },
  handleRemove(file) {
      let idxKey = this.listImgs.findIndex(item => item.uid===file.uid)


    //console.log('RM', idxKey, file, fileList, this.listImgs);
      if(file.onServer){
          this.imgsBash.push(file._id);
      }
      if(idxKey>=0){
          this.listImgs.splice(idxKey, 1);
      }
  },
  handlePictureCardPreview(file) {
    
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
  },
  submitUpload() {
    //console.log( this.$refs.upload);
  },
  onBeforeUpload(file)
  {
      const isIMAGE = file.type === 'image/jpeg'||'image/gif'||'image/png';
      const isLt1M = file.size / 1024 / 1024 < 2;

      if (!isIMAGE) {
        Swal.fire({
              type: 'warning',
              icon: "warning",
              text: 'El archivo de carga solo puede estar en formato de imagen',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
          })
          return isLt1M;
      }
      if (!isLt1M) {
        Swal.fire({
              type: 'warning',
              icon: "warning",
              text: 'El tamaño de la imagen no puede exceder 2 MB',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
          })
          return isLt1M;
      }
  },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
           
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Información general</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>
            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <form>
                 
                  <div class="row">
                   
                  
                    <div class="col-md-2">
                      <div class="mb-3">
                        <label for="alt">Ruta</label>
                         <select class="form-control select2" v-model="objectData.name_route"  :class="{
                            'is-invalid': submitted && $v.objectData.role.$error,
                          }">
                        <option :value="role.value" v-for="role in info_page.routes" :key="role.value" v-text="role.text"></option>
                        </select>
                        
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="title">Título</label>
                        <input id="title"  name="title" type="text" class="form-control"  v-model="objectData.title"
                          :class="{
                            'is-invalid': submitted && $v.objectData.title.$error,
                          }" />
                        <div v-if="submitted && $v.objectData.title.$error" class="invalid-feedback">
                          <span v-if="!$v.objectData.title.required">Campo requerido</span >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="title">Type</label>
                        <input id="title"  name="title" type="text" class="form-control"  v-model="objectData.type"
                          :class="{
                            'is-invalid': submitted && $v.objectData.title.$error,
                          }" />
                        <div v-if="submitted && $v.objectData.title.$error" class="invalid-feedback">
                          <span v-if="!$v.objectData.title.required">Campo requerido</span >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-3">
                        <label for="subtitle">Espacio superior(0 - 100)</label>
                        <input id="subtitle"  name="subtitle" type="text" class="form-control"  v-model="objectData.top" />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-3">
                        <label for="subtitle">Color de texto</label>
                        
                            <b-form-input id="color" type="color" name="color" class="form-control form-control-color"   v-model="objectData.text_color" ></b-form-input>
                         
                      </div>
                    </div>
                  </div>
                
               <div class="row">
                <div class="col-12">
                    <div class="p-4 border-top">
                      
                <el-upload
                action=""
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove"
                                    accept="image/jpeg,image/gif,image/png"
                                    :before-upload="onBeforeUpload"
                                    :http-request="addAttachment"
                                    :limit="1"
                                    :on-exceed="handleExceed"
                                    :file-list="listImgs"
                                    :auto-upload="true"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
                    </div>
                   </div>
               </div>
                </form>
              </div>
            </b-collapse>
          </div>


          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-2
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Meta Data SEO</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              data-parent="#addproduct-accordion"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="mb-3">
                        <label for="metatitle">Meta título</label>
                        <input
                          id="metatitle"
                          name="metatitle"
                          type="text"
                          class="form-control"
                          v-model="objectData.seo_title"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="mb-3 mb-0">
                    <label for="metadescription">Meta Descripción</label>
                    <textarea
                      class="form-control"
                      id="metadescription"
                      v-model="objectData.seo_description"
                      rows="4"
                    ></textarea>
                  </div>
                  <div class="mb-3 mb-0">
                    <label for="metadescription">Script Head</label>
                    <textarea
                      class="form-control"
                      id="metadescription"
                      v-model="objectData.head_html"
                      rows="4"
                    ></textarea>
                  </div>
                  <div class="mb-3 mb-0">
                    <label for="metadescription">Script Body</label>
                    <textarea
                      class="form-control"
                      id="metadescription"
                      v-model="objectData.body_html"
                      rows="4"
                    ></textarea>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>
         
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger"  @click.prevent="cancelForm()">
          <i class="uil uil-times me-1"></i> Cancelar
        </a>
        <a class="btn btn-success ms-1"  @click.prevent="validateForm()">
          <i class="uil uil-file-alt me-1" ></i> Guardar cambios
        </a>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
