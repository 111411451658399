export default class {

    constructor(obj = {}) {
        this.init(obj);
    }

  /**
  
   *
   * @param obj
   */
  init(obj = {}) {

       

        this.fillable = {
          id:null,
          name_route: null,
          seo_description: null,
          seo_title: null,
          top: 50,
          head_html: null,
          body_html: null,
          text_color: '#000000',
          title: null,
          path: null,
          variable: 0,
          type: null
        };

        if (obj) {
            Object.keys(this.fillable).forEach( key => {
                this[ key ] = obj[ key ] || this.fillable[ key ];
            });
        }

    }   
}
